<template>
<v-card flat height="100%">
  <v-card class="card-background rounded-lg">
    <v-container fill-height fluid class="ma-0 pa-0">
        <v-row align="center" justify="center" no-gutters>
            <v-col align="center" justify="center" no-gutters>
                <p class="welcome">Welcome to Your Virtual Meeting</p>
                <v-card class="card rounded-lg">
                    <v-row class="card-header" align="center" justify="center" no-gutters>
                        <span class="quick-start">Oops...!!</span>
                    </v-row>
                    <v-col class="ma-0 pa-0">
                        <div style="height: 30px;"/>
                        <v-icon size="60" class="circle-ripple" color="#d82e3d">
                            {{"mdi-close-circle"}}
                        </v-icon>

                        <div style="height: 30px;"/>
                        <p class="form-title">Meeting not found</p>
                        <p class="center-text">Please contact us</p>

                        <div style="height: 40px;"/>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
  </v-card>
</v-card>
</template>

<script>
  export default {
    name: 'NoPermission',
    components: {

    },
    data() {
      return {
          
      };
    },
    mounted() {
        
    },
    methods: {
      close() {
        window.close();
      },
    }
  };
</script>

<style scoped>
 @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

    .app-background{
        background-image: linear-gradient(to bottom, #fbfbfb, #f4f8fc);
    }

    .card-background {
        height: 100%;
        margin: 32px 28px 0px 28px;
        background-image: linear-gradient(to bottom, #f9f9f9, #f4f8fc);
    }

    .welcome {
        padding: 0px;
        margin: 0 0 40px 0;
        font-family: "Lato";
        font-size: 35px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #243c49;
    }

    .card {
        width: 45%;
        padding: 0;
        margin: 0;
    }

    .card-header {
        padding: 0;
        margin: 0;
        height: 5%;
        background-image: linear-gradient(93deg, #f1f1f1 0%, #e9eff0 99%);
    }

    .quick-start {
        padding: 0px;
        margin: 20px;
        font-size: 20px;
        font-family: 'Lato';
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;  
        color: #243c49;
    }

    .form-title {
        padding: 0px;
        margin: 22px 16px 10px 0;
        font-size: 24px;
        font-family: 'Lato';
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #c81717;
    }

    .start {
        margin: 20px 0 0 0;
        padding: 0px;
        font-family: 'Lato';
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: none !important;
        background-image: linear-gradient(93deg, #f1f1f1 0%, #e9eff0 99%);
    }
        
    .circle-ripple {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        animation: ripple 3s linear infinite;
        margin: 48px 0 0 0px;
    }

    @keyframes ripple {
        0% {
            box-shadow: 0 0 0 0 rgba(216, 46, 61, 0.3),
                        0 0 0 0px rgba(216, 46, 61, 0.3);
        }
        25% {
            box-shadow: 0 0 0 0 rgba(216, 46, 61, 0.3),
                        0 0 0 30px rgba(216, 46, 61, 0.3);
        }
        50% {
            box-shadow: 0 0 0 60px rgba(216, 46, 61, 0),
                        0 0 0 60px rgba(216, 46, 61, 0);
        }
    }

    @media screen and (max-width: 960px) {
        .card-background {
            background-image: linear-gradient(to bottom, #f9f9f9, #f4f8fc);
            height: 100%;
            margin: 0px;
        }

        .card {
            width: 90%;
            padding: 0;
            margin: 0;
        }

        .welcome {
            padding: 0px;
            margin: 0 0 48px 0;
            font-size: 20px;
            font-family: 'Lato';
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #243c49;
        }

        .quick-start {
            padding: 0px;
            margin: 20px;
            font-size: 16px;
            font-family: 'Lato';
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;  
            color: #243c49;
        }

        .form-title {
            padding: 0px;
            margin: 0 0 10px 0;
            font-size: 18px;
            font-family: 'Lato';
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #c81717;
        }

        .center-text {
            padding: 0px;
            margin: 0 0 7px 0;
            font-size: 14px;
            font-family: 'Lato';
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #243c49;
            text-align: center;
        }
    }
</style>
